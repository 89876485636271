/* MESH LOADER */
.mesh-loader {
    overflow: hidden;
    height: inherit;
    width: inherit;
    height: calc(100vh - 88px);
  }
  
  .mesh-loader .circle {
    width: 30px;
    height: 30px;
    position: absolute;
    background: #fa0909;
    border-radius: 50%;
    margin: -15px;
    -webkit-animation: mesh 3s ease-in-out infinite -1.5s;
    animation: mesh 3s ease-in-out infinite -1.5s;
  }
  
  .mesh-loader>div .circle:last-child {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  
  .mesh-loader>div {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  .mesh-loader>div:last-child {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  
  @keyframes mesh {
    0% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
  
    50% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  
    50.1% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }